import { useEffect, useState } from "react";
import CastleUrl from "../../assets/images/castle.png";
import Hero1Url from "../../assets/images/heroes/1.png";
import Hero2Url from "../../assets/images/heroes/2.png";
import Hero3Url from "../../assets/images/heroes/3.png";
import Hero4Url from "../../assets/images/heroes/4.png";

interface Hero {
    position: [number, number];
    width: number;
    rotation: number;
    url: string;
    zIndex: number;
    c: number;
}

export function HeroesImage() {
    let [heroes, setHeroes] = useState<Hero[]>([
        {
            url: Hero1Url,
            position: [-5, 30],
            width: 50,
            rotation: 0,
            zIndex: 30,
            c: 0
        },
        {
            url: Hero2Url,
            position: [15, 25],
            width: 55,
            rotation: 0,
            zIndex: 40,
            c: 0.4
        },
        {
            url: Hero3Url,
            position: [35, 23],
            width: 60,
            rotation: 0,
            zIndex: 50,
            c: -0.2
        },
        {
            url: Hero4Url,
            position: [68.1, 48],
            width: 43,
            rotation: 0,
            zIndex: 40,
            c: 0
        }
    ]);
    useEffect(()=>{
        let stop = false;
        const frame = () => {
            setHeroes(heroes => {
                const newHeroes = [...heroes];
                newHeroes.forEach(h => h.c+=0.005);
                return newHeroes;
            });
            if (!stop) requestAnimationFrame(frame);
        };
        frame();
        return () => {
            stop = true;
        }
    }, []);

    return <div className="relative max-w-full" style={{paddingTop: `${10.7/16*100}%`}}>
        <div className="absolute w-full top-0 left-0">
            <div className="overflow-hidden rounded-2xl">
                <div 
                    className="inset-0 w-full h-full z-10 absolute rounded-2xl"
                    style={{
                        background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 36.93%, rgba(21, 24, 20, 0.73) 100%)"
                    }}
                />
                <img src={CastleUrl} alt="Castle" className="block w-full" />
            </div>
            {heroes.map(hero => <img src={hero.url} key={hero.url} className="absolute" style={{
                left: `${hero.position[0]}%`,
                top: `${hero.position[1]}%`,
                width: `${hero.width}%`,
                transform: `rotate(${hero.rotation}deg) translateY(${Math.sin(hero.c) * 3}%)`,
                zIndex: hero.zIndex
            }}/>)}
        </div>
    </div>
}
