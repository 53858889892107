import { ReactComponent as PlaySvg } from "../../assets/images/play.svg";
import LongPipeUrl from "../../assets/images/long-pipe.png";
import { FormattedMessage } from "react-intl";
import { DefaultButton } from "../buttons/DefaultButton";
import styled from "styled-components";


export function FaqHeader(props: { onHowToOpen: () => void }) {
    return <div style={{ maxWidth: '1440px' }} className="p-6 md:p-0 md:mx-auto">
        <div className="flex flex-col md:flex-row w-full gap-8 border border-stroke-light rounded-2xl">
            <div className="flex flex-col items-center justify-center p-6 py-20 md:w-1/2">
                <div className="text-dark text-4xl font-bold">
                    <FormattedMessage id="NFTs für Neulinge" />
                </div>
                <div className="text-light-dark mt-2 font-sofia-pro font-bold text-center text-lg">
                    <FormattedMessage id="Was ist ein NFT und wie sichere ich mir eines?" />
                </div>
                <div className="mt-6 w-full flex justify-center">
                    <DefaultButton onClick={() => props.onHowToOpen()}>
                        <FormattedMessage id="Bitte erklärt mir das ganz simpel" />
                    </DefaultButton>
                </div>
            </div>
            <div className="md:w-1/2 p-3">
                <img src={LongPipeUrl} className="w-full h-full object-contain max-h-96" />
            </div>
        </div>
    </div>
}
