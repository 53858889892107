import { useState } from "react";
import { ReactComponent as PlusSvg } from "../../assets/icons/plus-circle.svg";
import { FormattedMessage } from "react-intl";
import {DISCORD_URL} from "../../config";

interface FaqItem {
    header: string;
    content: string;
}

const faqItems: FaqItem[] = [
    {
        header: "Sind die NFTs limitiert erhältlich?",
        content: "Ja! Die Helden sind seit 1876 im Schloss – und genauso viele haben sich als NFT zur Verfügung gestellt.",
    },
    {
        header: "Was kostet ein NFT?",
        content: "Bestechen konnte man unsere Helden noch nie – du musst also lediglich die Gas Fee (Transaktionsgebühren) für das Minting (ca. 8 Franken) bezahlen. ",
    },
    {
        header: "Ab wann kann ich mir meinen Hero sichern?",
        content: "Der Public Mint startet am 31. Oktober. Falls du dich für die \"Whitelist\" (siehe unten) registriert hast, kannst du dir dein NFT schon ab dem 24.10.2022 sichern.",
    },
    {
        header: "Was ist denn eine Whitelist?",
        content: "Das ist im NFT-Jargon so etwas wie eine VIP-Liste.",
    },
    {
        header: "Wie komme ich auf die Whitelist?",
        content: "Einfach die Augen offen halten – wir werden unsere Fans belohnen und euch direkt kontaktieren…",
    },
    {
        header: "Wie viele NFTs kann ich kaufen?",
        content: "Pro Wallet kannst du einen Castle Hero beziehen.",
    },
    {
        header: "Welche Blockchain verwendet ihr?",
        content: "Die Castle Heroes haben ihr zu Hause auf der Ethereum Blockchain. Wertstabil und Krisenfest.",
    },
    {
        header: "Wo erhalte ich weitere Informationen?",
        content: "In unserem {communityDiscord}. Dort triffst du online auf viele weitere Castle-Hero-Holder und erhältst jede Menge weitere Informationen sowie exklusive Ankündigungen für die Community.",
    },
    {
        header: "Sind alle Castle Heroes gleich?",
        content: "Nein! Jeder Castle Hero ist einzigartig und wird zufällig vergeben. Aber alle haben die selben tollen Benefits gemeinsam."
    }
];

export function FaqSection() {
    const [activeItem, setActiveItem] = useState<string|null>(null);
    
    const onClick = (item: FaqItem) => setActiveItem(activeItem => activeItem!==item.header?item.header:null);

    return <div className="p-6 md:p-0 max-w-full md:mt-6 m-auto" style={{maxWidth: "1440px"}}>
        <div className="border border-stroke-light rounded-2xl font-sofia-pro p-10">
            {faqItems.map((item, i) => <div
                key={i}
                className={`flex border-stroke-light gap-5 py-5 select-none ${i!==faqItems.length-1?'border-b':''}`}
            >
                <div>
                    <PlusSvg className={`cursor-pointer w-5 h-5 mt-1 transition-transform ${activeItem === item.header ? '-rotate-45' : ''}`}
                        onClick={() => onClick(item)}
                    />
                </div>
                <div>
                    <div className="text-dark font-bold text-lg cursor-pointer" onClick={() => onClick(item)}>
                        <FormattedMessage id={item.header} />
                    </div>
                    <div className={`text-light-dark text-0 transition-all ${activeItem === item.header ? 'mt-5' : 'mt-0'}`}
                        style={{
                            fontSize: `${activeItem === item.header ? '16px' : '0px'}`
                        }}
                    >
                        <FormattedMessage id={item.content}
                                          values={{
                                              communityDiscord: <a href={DISCORD_URL} className="text-active font-bold underline">
                                                  <FormattedMessage id={"Community-Discord"} />
                                              </a>
                                          }}
                        />
                    </div>
                </div>
            </div>)}
        </div>
    </div>;
}