import { FormattedMessage } from "react-intl";
import { ReactComponent as LogoWide } from "../../assets/images/logo-wide-white.svg";
import { ReactComponent as Discord } from "../../assets/images/discord.svg";
import { useState } from "react";
import { Popup } from "../common/Popup";
import { DefaultButton } from "../buttons/DefaultButton";
import {DISCORD_URL, useSiteLinksPrefix} from "../../config";
import { OnNavHandler } from "../../App";


export function FooterSection(props: {onNav: OnNavHandler}) {
    const [termsOpen, setTermsOpen] = useState<boolean>(false);
    const SITE_LINKS_PREFIX = useSiteLinksPrefix();

    return <>
        {termsOpen&&<Popup onClose={()=>setTermsOpen(false)}>
            <div className="text-dark text-center text-3xl font-bold">
                <FormattedMessage id="t_header" />
            </div>
            <div className="text-light-dark text-center font-sofia-pro mt-3 font-bold">
                <FormattedMessage id="t_subheader" />
            </div>
            <div className="text-light-dark font-sofia-pro flex flex-col gap-2 mt-3">
                <div className="flex gap-1">
                    <div>1</div>
                    <div>
                        <FormattedMessage id="t_1" />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div>2</div>
                    <div>
                        <FormattedMessage id="t_2" />
                    </div>
                </div>
                <div className="flex gap-1 ml-8">
                    <div>a.</div>
                    <div>
                        <FormattedMessage id="t_2_a" />
                    </div>
                </div>
                <div className="flex gap-1 ml-8">
                    <div>b.</div>
                    <div>
                        <FormattedMessage id="t_2_b" />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div>3</div>
                    <div>
                        <FormattedMessage id="t_3"
                        values={{
                            link: <a href={"https://feldschloesschen.ch/Teilnahmebedingungen-wettbewerbe"} className={"text-active"}>
                                <FormattedMessage id={"t_3_link"} />
                            </a>
                        }}
                        />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div>4</div>
                    <div>
                        <FormattedMessage id="t_4" />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div>5</div>
                    <div>
                        <FormattedMessage id="t_5" />
                    </div>
                </div>
                <div className="flex gap-1 ml-8">
                    <div>a.</div>
                    <div>
                        <FormattedMessage id="t_5_a"
                            values={{
                                bold: <b><FormattedMessage id={"t_5_a_bold"} /></b>
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1 ml-8">
                    <div>b.</div>
                    <div>
                        <FormattedMessage id="t_5_b"
                            values={{
                                bold: <b><FormattedMessage id={"t_5_b_bold"}/></b>
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div>6</div>
                    <div>
                        <FormattedMessage id="t_6" />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div>7</div>
                    <div>
                        <FormattedMessage id="t_7" />
                    </div>
                </div>
                <div className="mt-6 flex justify-center">
                    <DefaultButton className="w-72" onClick={()=>setTermsOpen(false)}>
                        <FormattedMessage id="Alles klar!" />
                    </DefaultButton>
                </div>
            </div>
        </Popup>}
        <div className="p-6 md:p-0">
            <div className="mt-12 mb-6 bg-dark p-10 mx-auto rounded-2xl" style={{ maxWidth: "1440px" }}>
                <div className="flex flex-col md:flex-row">
                    <div className="flex-shrink-0 w-full md:w-80">
                        <LogoWide className="w-full md:w-10/12" />
                        <div className="font-sofia-pro text-white mt-6">
                            <FormattedMessage 
                                id="Castle Heroes ist ein Projekt von {company_name}."
                                values={{
                                    "company_name": <a href="https://feldschloesschen.ch/" className="underline">
                                        <FormattedMessage id="Feldschlösschen" />
                                    </a>
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex md:hidden flex-col mt-12 gap-8 text-white font-bold items-center">
                        <Discord className="w-6" />
                        <a href={SITE_LINKS_PREFIX+`#story`} onClick={()=>props.onNav('story')}><FormattedMessage id="Story" /></a>
                        <a href={SITE_LINKS_PREFIX+`#utility`} onClick={()=>props.onNav('utility')}><FormattedMessage id="Utility" /></a>
                        <a href={SITE_LINKS_PREFIX+`"#faq`} onClick={()=>props.onNav('faq')}><FormattedMessage id="FAQ" /></a>
                        <a href="https://feldschloesschen.ch/impressum/"><FormattedMessage id="Impressum" /></a>
                        <a href="https://webpolicies.ch/"><FormattedMessage id="Nutzungsbedingungen" /></a>
                        <div className="cursor-pointer"
                            onClick={()=>setTermsOpen(true)}
                        ><FormattedMessage id="Teilnahmebedingungen" /></div>
                    </div>
                    <div className="hidden md:flex grow flex-col items-center justify-center text-white font-bold gap-8">
                        <a href={SITE_LINKS_PREFIX+`#story`} onClick={()=>props.onNav('story')}><FormattedMessage id="Story" /></a>
                        <a href={SITE_LINKS_PREFIX+`#utility`} onClick={()=>props.onNav('utility')}><FormattedMessage id="Utility" /></a>
                        <a href={SITE_LINKS_PREFIX+`#faq`} onClick={()=>props.onNav('faq')}><FormattedMessage id="FAQ" /></a>
                    </div>
                    <div className="hidden md:flex flex-col justify-start items-end text-white font-bold">
                        <a href={DISCORD_URL}><Discord className="w-6" /></a>
                        <a href="https://feldschloesschen.ch/impressum/" className="mt-12"><FormattedMessage id="Impressum" /></a>
                        <a href="https://webpolicies.ch/" className="mt-8"><FormattedMessage id="Nutzungsbedingungen" /></a>
                        <div className="cursor-pointer mt-8"
                            onClick={()=>setTermsOpen(true)}
                        ><FormattedMessage id="Teilnahmebedingungen" /></div>
                    </div>
                </div>
                <div className="border-t border-opacity-10 border-white p-8 pb-0 text-center text-white mt-12 md:mt-20 font-bold">
                    © <FormattedMessage id={"Feldschlösschen, seit 1876 gebraut in der Schweiz"} />
                </div>
            </div>
        </div>
    </>;
}