import { FormattedMessage } from "react-intl";

import Hero1Url from "../../assets/images/grid/1_hero.png";
import Hero1Background from "../../assets/images/grid/1_bg.png";
import Hero1Grad from "../../assets/images/grid/1_grad.png";

import Hero2Url from "../../assets/images/grid/2_hero.png";
import Hero2Background from "../../assets/images/grid/2_bg.png";
import Hero2Grad from "../../assets/images/grid/2_grad.png";

import Hero3Url from "../../assets/images/grid/3_hero.png";
import Hero3Background from "../../assets/images/grid/3_bg.png";
import Hero3Grad from "../../assets/images/grid/3_grad.png";

import Hero4Url from "../../assets/images/grid/4_hero.png";
import Hero4Background from "../../assets/images/grid/4_bg.png";
import Hero4Grad from "../../assets/images/grid/4_grad.png";

interface Hero {
    heroUrl: string;
    gradUrl: string;
    bgUrl: string;
    header: string;
    description: string;
}

export function GridSection() {
    const heroes = [
        {
            heroUrl: Hero1Url,
            gradUrl: Hero1Grad,
            bgUrl: Hero1Background,
            header: "Deine Discounts!",
            description: "Erhalte exklusive Vergünstigungen auf justDrink.ch."
        },
        {
            heroUrl: Hero2Url,
            gradUrl: Hero2Grad,
            bgUrl: Hero2Background,
            header: "Dein VIP-Status",
            description: "Sei Teil von unvergesslichen Live-Events, Meet-Ups und Get-Togethers."
        },
        {
            heroUrl: Hero3Url,
            gradUrl: Hero3Grad,
            bgUrl: Hero3Background,
            header: "Deine Gadgets",
            description: "Keine Staubfänger ‒ garantiert!"
        },
        {
            heroUrl: Hero4Url,
            gradUrl: Hero4Grad,
            bgUrl: Hero4Background,
            header: "Deine Chance",
            description: "Du bist bei allen Verlosungen von Feldschlösschen automatisch im Lostopf."
        }
    ] as Hero[];

    return <div className="flex flex-nowrap md:flex-wrap mt-8 overflow-y-auto md:overflow-hidden mx-6 md:mx-auto" 
        style={{maxWidth: "1440px"}}
    >
        {heroes.map((hero, i) => <div key={i} className="flex-shrink-0 w-80 h-96 md:w-1/2 pr-6 md:p-3">
            <div className="relative rounded-2xl overflow-hidden w-full h-full">
                <img src={hero.bgUrl} className="absolute w-full h-full z-10 object-cover" />
                <img src={hero.gradUrl} className="absolute w-full h-full z-20 object-cover" />
                <img src={hero.gradUrl} className="absolute w-full h-full z-30 object-cover" />
                <div className="w-full h-full absolute z-40 inset-0 flex flex-col items-center text-white p-6">
                    <img src={hero.heroUrl} alt="Hero" className="h-1/2 md:h-4/6" />
                    <div className="text-2xl md:text-4xl font-bold mt-16 md:mt-6 text-center">
                        <FormattedMessage id={hero.header} />
                    </div>
                    <div className="mt-2 text-center md:text-lg">
                        <FormattedMessage id={hero.description} />
                    </div>
                </div>
            </div>
        </div>)}
    </div>
}
