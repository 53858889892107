import {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ReactComponent as LogoWide} from "../assets/images/logo-wide.svg";
import HeroesUrl from "./heroes.png";


export const AgeGate = () => {
    const [show, setShow] = useState<boolean>(false);

    const [restricted, setRestricted] = useState<boolean>(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const skip_age_gate = url.searchParams?.get("skip_age_gate") === "1"
            || localStorage.getItem("skip_age_gate") === "1";

        if (skip_age_gate) {
            setSkipAgeGate();
        } else {
            setShow(true);
        }
    }, []);

    const setSkipAgeGate = () => {
        localStorage.setItem("skip_age_gate", "1");
        setShow(false);
    };

    const numbers: [string, (v: string)=>void][] = [];
    // eslint-disable-next-line
    for (let i = 0; i < 4; i++) numbers.push(useState<string>("Y"));

    let [focused, setFocused] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement|null>(null);

    const onChange = (value: string, index: number) => {
        if (value == "Backspace") {
            numbers[index][1]("Y");
            setFocused(v=>Math.max(0,v-1));
            setRestricted(false);
            return;
        }

        if (value < "0" || value > "9") return;
        if (index == 0 && value !== "1" && value !== "2") return;
        if (index == 1 && value !== "0" && value !== "9") return;

        setRestricted(false);

        numbers[index][1](value);
        const newNumbers = numbers.map(n=>n[0]);
        newNumbers[index] = value;
        const year = Number(newNumbers.map(n=>n[0].toString()).join(''));

        if (index === 3) {
            onDone(year);
        } else {
            setFocused(index+1);
        }
    };

    const onInput = (value: string) => {
        onChange(value, focused);
    }

    const focus = (index: number) => {
        const availableFocus = numbers.map(n=>n[0]).indexOf("Y");
        if (availableFocus != index) return;
        setFocused(index);
        inputRef.current?.focus();
    }

    const onDone = (year: number) => {
        const nowYear = (new Date()).getFullYear();
        if (nowYear - year >= 18) {
            setSkipAgeGate();
        } else {
            setRestricted(true);
        }
    };

    useEffect(()=>{
        const ev = (e: KeyboardEvent) => {
            if (e.target != inputRef.current || e.key == "Backspace") onInput(e.key);
        };
        window.addEventListener("keyup", ev);
        return () => {
            window.removeEventListener("keyup", ev);
        }
    }, [...numbers.map(n=>n[0]), focused]);

    if (!show) return null;

    return <div style={{
        zIndex: 9999
    }} className="fixed inset-0 w-full h-full bg-white flex flex-col justify-center items-center p-6 md:px-0 overflow-y-auto">
        <div className="shadow-dark-shadow p-4 md:p-6 w-[1000px] max-w-full rounded-2xl">
            <div className="flex justify-center">
                <LogoWide className={"w-[172px]"}/>
            </div>
            <div className={"text-center text-dark font-klint-pro font-bold text-2xl md:text-4xl mt-4 md:mt-6"}>
                <FormattedMessage id="Willkommen bei den Castle Heroes!" />
            </div>
            <div className="flex justify-center mt-4 md:mt-12">
                <img src={HeroesUrl} className="w-[480px] w-max-full"/>
            </div>
            <div className={"mt-6 md:px-20 flex justify-center"}>
                <div className="w-full border-stroke-light border rounded-2xl p-6" style={{maxWidth: "500px"}}>
                    <div className={"text-dark text-center font-bold"}>
                        <FormattedMessage id={"Bitte gib dein Geburtsdatum ein"} />
                    </div>
                    <div className={"mt-6 flex gap-4"}>
                        {numbers.map((n,k) => <NumberInput
                            key={k}
                            focused={focused===k}
                            value={n[0]}
                            onClick={()=>focus(k)}
                            restricted={restricted}
                        />)}
                    </div>
                    <input type={"tel"}
                           ref={inputRef}
                           onKeyPress={e=>onInput(e.key)}
                           className={"w-8"}
                           style={{fontSize:"1px",height: "0px"}}
                    />
                    <div className="text-center h-4 text-light-dark font-bold">
                        {restricted&&<FormattedMessage id="Sorry, du bist zu jung um die Seite zu sehen."/>}
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-6 text-center">
            <FormattedMessage id={"Bitte lies unsere {cookies_link}, {privacy_policy_link} und {notes_link}"}
                values={{
                    "cookies_link": <a href="https://webpolicies.ch/cookies-feldschloesschen/" className="underline"><FormattedMessage id="Cookie-Richtlinie" /></a>,
                    "privacy_policy_link": <a href="https://webpolicies.ch/datapolicy/" className="underline"><FormattedMessage id="Datenschutz-Richtlinie" /></a>,
                    "notes_link": <a href="https://webpolicies.ch/acceptable-use-policy/" className="underline"><FormattedMessage id="Nutzungshinweise" /></a>,
                }}
            />
        </div>
    </div>;
}

const NumberInput = (props: {onClick: ()=>void, value: string, focused: boolean, restricted: boolean}) => {
    return <div className={`flex-1 rounded-xl ${props.restricted?'bg-red':'bg-[#FAFAFA]'} transition-all border border-stroke-light flex flex-col gap-2 md:gap-6 items-center justify-center ` +
    `h-[70px] md:h-[150px]`} onClick={props.onClick}>
        <div
           className={`${props.restricted?'text-white':'text-dark'} transition-all text-[24px] h-[24px] md:text-[56px] md:h-[56px] w-full bg-inherit outline-none font-bold text-center`}
        >{props.value}</div>
        <div className={`h-[4px] md:h-[8px] w-8/12 ${props.focused?`${props.restricted?'bg-white':'bg-dark'} animate-pulse`:"bg-transparent"}`} />
    </div>
};
