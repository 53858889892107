import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DefaultButton } from "./components/buttons/DefaultButton";
import { Header } from "./components/common/Header";
import { Popup } from "./components/common/Popup";
import { BenefitsSection } from "./components/sections/BenefitsSection";
import { FaqHeader } from "./components/sections/FaqHeader";
import { FaqSection } from "./components/sections/FaqSection";
import { FooterSection } from "./components/sections/FooterSection";
import { GridSection } from "./components/sections/GridSection";
import { HeroesSlider } from "./components/sections/HeroesSlider";
import { HeroesTopSection } from "./components/sections/HeroesTopSection";
import { HowToPopup } from "./components/sections/HowToPopup";
import { JoinDiscordSection } from "./components/sections/JoinDiscordSection";
import { MintNowSection } from "./components/sections/MintNowSection";
import { SectionBlock2 } from "./components/sections/SectionBlock2";
import { DISCORD_URL, OPENSEA_LEARN_URL, COINBASE_URL } from "./config";
import {AgeGate} from "./agegate/AgeGate";

export type ScrollablePoint = "story" | "utility" | "faq";
export type OnNavHandler = (point: ScrollablePoint) => void;

function App() {
    const [howToOpen, setHowToOpen] = useState<boolean>(false);

    const storyRef = useRef<HTMLDivElement|null>(null),
        utilityRef = useRef<HTMLDivElement|null>(null),
        faqRef = useRef<HTMLDivElement|null>(null);

    const scrollMap: Record<ScrollablePoint, React.MutableRefObject<HTMLDivElement|null>> = {
        "story": storyRef,
        "utility": utilityRef,
        "faq": faqRef,
    }

    const onNav = (point: ScrollablePoint) => {
        if (Object.keys(scrollMap).includes(point) && scrollMap[point].current) {
            window.scrollTo({
                top: scrollMap[point].current?.offsetTop,
                behavior: 'smooth',
            });
        }
    }

    useEffect(()=>{
        onNav(window.location.hash.substring(1) as ScrollablePoint);
    }, []);

    return <>
        <AgeGate/>
        {howToOpen&&<HowToPopup onClose={() => setHowToOpen(false)}/>}
        <Header onNav={onNav}/>
        <HeroesTopSection onHowToOpen={()=>setHowToOpen(true)}/>
        <div ref={storyRef}><SectionBlock2/></div>
        <HeroesSlider/>
        <div ref={utilityRef}><BenefitsSection/></div>
        <GridSection/>
        <MintNowSection/>
        <div ref={faqRef}><FaqHeader onHowToOpen={()=>setHowToOpen(true)}/></div>
        <FaqSection/>
        <JoinDiscordSection/>
        <FooterSection onNav={onNav}/>
    </>
}

export default App;
