import {FormattedMessage} from "react-intl";
import CommunityUrl from "../../assets/images/community.png";
import CommunityBgMobileUrl from "../../assets/images/community-bg-mobile.png";
import CommunityBgPcUrl from "../../assets/images/community-bg-pc.png";

export function BenefitsSection() {
    return <div className="mt-12 md:mt-20">
        <div className="flex justify-center text-dark font-bold text-3xl md:text-5xl"><FormattedMessage id="Benefits" /></div>
        <div className="md:hidden mt-12 relative p-6 text-center text-white" style={{
            backgroundImage: `url(${CommunityBgMobileUrl})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `100%`
        }}>
            <div className="flex justify-center">
                <img src={CommunityUrl} alt="Community" className="absolute -top-10 w-7/12"/>
            </div>
            <div className="text-2xl font-bold mt-28">
                <FormattedMessage id="Community" />
            </div>
            <div className="mt-3">
                <FormattedMessage id="Werde Mitglied der ersten Feldschlösschen NFT Community!" />
            </div>
        </div>
        <div className="hidden md:flex mt-8 mx-auto w-full relative" style={{
            maxWidth: "1440px", 
            backgroundImage: `url(${CommunityBgPcUrl})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `100%`,
            height: `300px`
        }}>
            <div className="flex flex-col w-7/12 gap-6 justify-center items-center text-white">
                <div className="text-5xl font-bold">
                    <FormattedMessage id="Community" />
                </div>
                <div className="text-xl">
                    <FormattedMessage id="Werde Mitglied der ersten Feldschlösschen NFT Community!" />
                </div>
            </div>
            <img src={CommunityUrl} alt="Community" className="absolute -top-10 right-10 w-4/12"/>
        </div>
    </div>;
}
