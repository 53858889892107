import VideoImgUrl from "../../assets/images/video.png";
import {FormattedMessage} from "react-intl";
import {ReactComponent as Dot} from "../../assets/icons/dot.svg";

export function SectionBlock2() {
    return <div className="rounded-2xl border border-stroke-light p-6 flex flex-col md:flex-row mx-6 xl:mx-auto mt-12 xl:mt-0 gap-20"
        style={{maxWidth: "1440px"}}
    >
        <div className="md:w-1/2">
            <img src={VideoImgUrl} className="block w-full" alt="Video" />
        </div>
        <div className="md:w-1/2">
            <div className="text-dark font-bold text-3xl mt-2">
                <FormattedMessage id="Die 1876 Helden hinter Feldschlösschen" />
            </div>
            <div className="text-light-dark text-description-regular font-sofia-pro mt-2">
                <FormattedMessage id="Wesen, so klein, dass wir sie kaum bemerkt haben: Hinter Maischpfannen, Läuterbottichen und Lagertanks verborgen, verteidigen sie das Schloss seit jeher. Sie sind ein lebender Mythos und das bestgehütete Geheimnis der schönsten Brauerei der Schweiz." />
            </div>
            <div className="text-dark font-bold text-lg mt-6">
                <FormattedMessage id="Fragst du dich nun, wer die Helden hinter Feldschlösschen sind? Werde Teil der Community und unterstütze einen Hero bei seiner Mission. Als NFT-Holder:"/>
            </div>
            <div className="text-light-dark text-description-regular font-sofia-pro mt-2">
                <div className="flex gap-2 items-start">
                    <Dot className="w-2 shrink-0"/>
                    <div>
                        <FormattedMessage id="wirst du Teil des historischen Bündnisses und damit Teil der ersten, digitalen Community von Feldschlösschen." />
                    </div>
                </div>
                <div className="flex gap-2 items-start">
                    <Dot className="w-2 shrink-0"/>
                    <div>
                        <FormattedMessage id="geniesst du Vergünstigungen beim Bierkauf auf justDrink.ch." />
                    </div>
                </div>
                <div className="flex gap-2 items-start">
                    <Dot className="w-2 shrink-0"/>
                    <div>
                        <FormattedMessage id="hast du Zutritt zu exklusiven Events von Feldschlösschen, umgeben von weiteren NFT-Heroes." />
                    </div>
                </div>
                <div className="flex gap-2 items-start">
                    <Dot className="w-2 shrink-0"/>
                    <div>
                        <FormattedMessage id="eine Chance auf heroische Gadgets, die garantiert keine Staubfänger sind." />
                    </div>
                </div>
                <div className="flex gap-2 items-start">
                    <Dot className="w-2 shrink-0"/>
                    <div>
                        <FormattedMessage id="nimmst du immer automatisch bei Verlosungen von Feldschlösschen teil." />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
