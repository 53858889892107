import PlantUrl from "../../assets/images/plant.png";
import {FormattedMessage} from "react-intl";
import { OrangeButton } from "../buttons/OrangeButton";
import {useMintUrl} from "../../config";


export function MintNowSection() {
    const MINT_URL = useMintUrl();

    return <div>
        <div className="flex md:hidden relative rounded-xl overflow-hidden m-6" style={{height: "400px"}}>
            <img src={PlantUrl} className="inset-0 absolute h-full w-full object-cover object-left-bottom z-10"/>
            <div className="inset-0 aboslute h-full w-full z-20" style={{background: "linear-gradient(0deg, rgba(142, 91, 44, 0.6), rgba(142, 91, 44, 0.6))"}}/>
            <div className="inset-0 absolute h-full w-full z-30 flex flex-col justify-center items-center">
                <div className="font-bold text-white text-2xl">
                    <FormattedMessage id="Sichere dir jetzt dein NFT" />
                </div>
                <div className="mt-6">
                    <a href={MINT_URL}>
                        <OrangeButton>
                            <FormattedMessage id="Voranmelden" />
                        </OrangeButton>
                    </a>
                </div>
            </div>
        </div>
        <div className="hidden md:flex relative rounded-xl overflow-hidden m-6 mx-auto w-full" style={{height: "600px", maxWidth: "1440px"}}>
            <img src={PlantUrl} className="inset-0 absolute h-full w-full object-cover object-left-bottom z-10"/>
            <div className="inset-0 aboslute h-full w-full z-20" style={{background: "linear-gradient(0deg, rgba(142, 91, 44, 0.6), rgba(142, 91, 44, 0.6))"}}/>
            <div className="inset-0 absolute h-full w-full z-30 flex flex-col justify-center items-center">
                <div className="font-bold text-white text-7xl">
                    <FormattedMessage id="Sichere dir jetzt dein NFT" />
                </div>
                <div className="mt-6">
                    <a href={MINT_URL}>
                        <OrangeButton>
                            <FormattedMessage id="Voranmelden" />
                        </OrangeButton>
                    </a>
                </div>
            </div>
        </div>
    </div>
}
