import {useLanguage} from "./app/translation";

const HOME_URL = "https://stg.castle-heroes.ch?skip_age_gate=1";
const MINT_URL = "https://stgwl.castle-heroes.ch?skip_age_gate=1";
export const DISCORD_URL = "https://discord.gg/Uq8wBsGBbX";
export const COINBASE_URL = "https://www.coinbase.com/";
export const METAMASK_URL = "https://metamask.io/download/";
export const OPENSEA_LEARN_URL = "https://opensea.io/learn";
const SITE_LINKS_PREFIX = "";

export const useHomeUrl = () => {
    const language = useLanguage();
    return HOME_URL+`&lang=${language}`;
}

export const useMintUrl = () => {
    const language = useLanguage();
    return MINT_URL+`&lang=${language}`;
}

export const useSiteLinksPrefix = () => {
    // const language = useLanguage();
    // return SITE_LINKS_PREFIX+`&lang=${language}`;
    return SITE_LINKS_PREFIX;
}

